import React, { Fragment, useEffect } from 'react';
import './About.scss';
import {
  AnimatedBackgroundBox,
  Carousel,
  CarouselScreen,
  FullViewportStage,
  Footer,
  FooterNav,
  LimitedWidthText,
  TransparentTextStage,
  BackGroundImageStage,
  VideoStage,
  HorizontalSlider,
  ContactInfoBlock,
} from '../../components';
import AwardsList from '../../components/AwardsList/AwardsList';
import ScrollTransition from '../../components/Carousel/Transitions/ScrollTransition';
import SlideTransition from '../../components/Carousel/Transitions/SlideTransition';
import ScrollDown from '../../components/ScrollDown/ScrollDown';

const AboveTheFold = ({ goToScreen, aboutPage }) => (
  <FullViewportStage centered>
    <h1 className="typography typography__header1">{aboutPage.title}</h1>
    <ScrollDown onClick={() => goToScreen(1)} />
  </FullViewportStage>
);

const LastScreen = ({
  scrollTop,
  scrollToTop,
  goToScreen,
  aboutPage,
  data, // ergh this name is completely unclear and I have no idea what is going on, TODO @ author -- fix this.
  awards,
  location,
}) => {
  const goToTop = async () => {
    goToScreen(0, true);
  };

  useEffect(() => {
    const hash = location.hash;
    if (hash.length) {
      goToScreen(hash, true);
    }
  }, []);

  return (
    <Fragment>
      <div id="awards" />
      <FullViewportStage centered spaced black regularOverflow>
        <h2 className="typography__header2">{aboutPage.awardsHeadline}</h2>
        <AwardsList awards={awards} />
      </FullViewportStage>
      <Footer onArrowClick={() => goToTop()} />
    </Fragment>
  );
};

const About = props => {
  const { text } = props.pageContext;
  const { location, data } = props;
  const { aboutPage, awards, teamMembers } = data;
  const images = aboutPage.images.map(step => ({
    image: step,
  }));

  return (
    <Fragment>
      <VideoStage
        videoId={aboutPage.videoID}
        videoBackground
        imageBackgroundSrc={aboutPage.titleImageHighRes}
        fixedBackground
      />

      <Carousel location={location}>
        <CarouselScreen transition={ScrollTransition}>
          <AboveTheFold aboutPage={aboutPage} />
        </CarouselScreen>

        <CarouselScreen
          scrollable={true}
          transition={ScrollTransition}
          transitionOptions={{ lastOfKind: true }}
        >
          <TransparentTextStage
            text={aboutPage.claim1 && aboutPage.claim1.claim1}
          />
        </CarouselScreen>

        <CarouselScreen transition={SlideTransition}>
          <FullViewportStage black centered spaced>
            <LimitedWidthText
              text={aboutPage.claim2 && aboutPage.claim2.claim2}
            />
          </FullViewportStage>
        </CarouselScreen>

        <CarouselScreen scrollable={true} transition={SlideTransition}>
          <BackGroundImageStage img={aboutPage.image2HighRes}>
            <FullViewportStage regularOverflow spaced centered>
              <h2 className="typography__header3">
                {aboutPage.claim3Big && aboutPage.claim3Big.claim3Big}
              </h2>
            </FullViewportStage>
          </BackGroundImageStage>
        </CarouselScreen>
        <CarouselScreen transition={SlideTransition}>
          <FullViewportStage black centered spaced>
            <LimitedWidthText
              text={aboutPage.claim4 && aboutPage.claim4.claim4}
            />
          </FullViewportStage>
        </CarouselScreen>

        <CarouselScreen transition={SlideTransition}>
          <FullViewportStage black centered>
            <HorizontalSlider data={images} />
          </FullViewportStage>
        </CarouselScreen>

        <CarouselScreen scrollable={true}>
          <FullViewportStage black regularOverflow spacedTopBottom>
            <h2 className="typography__header2">{aboutPage.teamHeadline}</h2>
            <div className={'teamGrid'}>
              {teamMembers.map(member => (
                <div className={'teamGrid__gridItem'} key={member.sluggedName}>
                  <AnimatedBackgroundBox bg={member.highResImage}>
                    <div className={'teamGrid__teamInfoContainer'}>
                      <p
                        className={
                          'typography typography__paragraph teamGrid__role'
                        }
                      >
                        {member.role}
                      </p>
                      <h4
                        className={
                          'typography typography__header4 typography--uppercase teamGrid__name'
                        }
                      >
                        {member.name}
                      </h4>
                      <p
                        className={
                          'typography typography__paragraph teamGrid__email'
                        }
                      >
                        {member.eMailAddress}
                      </p>
                    </div>
                  </AnimatedBackgroundBox>
                </div>
              ))}
            </div>
          </FullViewportStage>
        </CarouselScreen>

        <CarouselScreen scrollable={true}>
          <BackGroundImageStage img={aboutPage.joinUsImageHighRes}>
            <FullViewportStage centered>
              <h2 className="typography__header2">
                {aboutPage.joinUsHeadline}
              </h2>
              <a
                href="mailto:info@dropout-films.de"
                className="typography__link typography__link--readMore"
              >
                {aboutPage.joinUsLink}
              </a>
            </FullViewportStage>
          </BackGroundImageStage>
        </CarouselScreen>

        <CarouselScreen scrollable={true}>
          <LastScreen
            data={data}
            aboutPage={aboutPage}
            awards={awards}
            location={location}
          />
        </CarouselScreen>
      </Carousel>
    </Fragment>
  );
};

export default About;
