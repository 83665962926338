import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../Layout/Layout';
import About from './About';

export default props => {
  const data = {
    aboutPage: props.data.aboutPage.nodes[0],
    teamMembers: props.data.teamMembers.nodes,
    awards: props.data.awards.nodes,
  };
  return (
    <Layout>
      <About {...props} data={data} />
    </Layout>
  );
};

export const query = graphql`
  query($locale: String!) {
    aboutPage: allContentfulAboutPage(
      filter: { node_locale: { eq: $locale } }
    ) {
      nodes {
        title
        videoID
        claim1 {
          claim1
        }
        claim2 {
          claim2
        }
        titleImageHighRes {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        image2HighRes {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        claim3Big {
          claim3Big
        }
        claim4 {
          claim4
        }
        images {
          title
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        teamHeadline
        joinUsHeadline
        joinUsLink
        joinUsImageHighRes {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        awardsHeadline
      }
    }

    teamMembers: allContentfulTeamMember(
      filter: { node_locale: { eq: $locale } }
    ) {
      nodes {
        sluggedName
        name
        role
        eMailAddress
        highResImage {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }

    awards: allContentfulAward(filter: { node_locale: { eq: $locale } }) {
      nodes {
        title
        isVisibleOnLanding
      }
    }
  }
`;
